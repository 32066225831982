import React from 'react'
import { Box, Grid, Heading, Text, Divider } from 'theme-ui'
import GatsbyImage from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import { AttractionMap, NearbyExtended, StayTheNight, SEO } from 'components'

function Nearby() {
  const imagesQuery = useStaticQuery(graphql`
    query NearbyPageImages {
      dales: file(relativePath: { eq: "dales-view@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dalesVista: file(relativePath: { eq: "dales-vista@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO title="Nearby Attractions" />
      <Box>
        <Grid
          columns={[null, null, null, '3fr 5fr']}
          gap={['xl', null, null, 'xxl']}
          py={['xl', 'xxl', 'xxxl']}
          px={['m', 'l']}
          variant="boxes.container"
        >
          <Box m={['auto']}>
            <Heading
              variant="heading.xl"
              mb="m"
              pb="m"
              sx={{
                borderBottom: 'solid 1px',
                borderColor: 'black.5',
              }}
            >
              <span
                sx={{
                  maxWidth: 'measure',
                }}
              >
                A perfect base for a host of activities
              </span>
            </Heading>

            <Text
              as="p"
              variant="stnd.body"
              mb="xl"
              sx={{ maxWidth: 'measure' }}
            >
              The Station Inn is a great pit stop, meal provider or overnight
              option for Yorkshire Dales visitors.
            </Text>

            <Text
              variant="subhead"
              sx={{ color: 'orange.dark', textDecoration: 'none' }}
              href="#nearby"
              as="a"
            >
              View nearby attractions
            </Text>
          </Box>
          <Grid sx={{ alignItems: 'center' }}>
            <Box
              sx={{
                boxShadow: 'l',
                borderRadius: 5,
              }}
            >
              <GatsbyImage
                fluid={imagesQuery.dales.childImageSharp.fluid}
                sx={{ borderRadius: 5 }}
                style={{ display: 'block', borderRadius: 5 }}
              />
            </Box>
          </Grid>
        </Grid>

        <Box id="nearby">
          <AttractionMap />
        </Box>

        <Box
          sx={{
            py: ['xl', null, 'xxl', 'xxxl'],
            px: 'm',
            maxWidth: 'measure',
            mx: 'auto',
            boxSizing: 'content-box',
          }}
        >
          <Heading variant="heading.l" mb={['s']}>
            The setting
          </Heading>
          <Box as="p">
            The Station Inn is a special place and its location is special too!
            Ribblesdale is one of the small number of Yorkshire Dales which
            drain out into the Irish Sea; the majority drain into the North Sea.
            The Inn and the Viaduct are situated at the head of the Dale very
            near the source of the River Ribble itself which runs some 75 miles
            through Yorkshire and Lancashire. Until 1974 the Dale was officially
            located in the West Riding of Yorkshire, but became part of Craven
            District in the new county of North Yorkshire when local government
            areas were redefined.
          </Box>
        </Box>

        <NearbyExtended />

        <BackgroundImage
          fluid={imagesQuery.dalesVista.childImageSharp.fluid}
          backgroundColor={`#040e18`}
        >
          <Box px="m">
            <Box variant="boxes.container" mx={'auto'} py="xxxxl">
              <Box
                sx={{
                  bg: 'white.100',
                  boxShadow: 'm',
                  maxWidth: 520,
                }}
              >
                <Box p={['l', 'xl']}>
                  <Heading variant="heading.xl">
                    Yorkshire Dales National Park
                  </Heading>
                  <Divider />
                  <Text as="p" mb="l" variant="stnd.xs">
                    The Station Inn is situated in the Yorkshire Dales National
                    Park, which was originally designated in 1954 and extended
                    in 2016. Whether your interest is in outdoor activities,
                    geology, history, arts and crafts or delicious food and
                    drink - the Dales, quite literally, has it all!
                  </Text>
                  <Text
                    variant="subhead"
                    sx={{
                      color: 'green.dark',
                      display: 'block',
                      textDecoration: 'none',
                    }}
                    mb="xs"
                    as="a"
                    href="https://www.yorkshiredales.org.uk/"
                    target="_blank"
                  >
                    → Yorkshire Dales
                  </Text>
                  <Text
                    variant="subhead"
                    sx={{
                      color: 'green.dark',
                      display: 'block',
                      textDecoration: 'none',
                    }}
                    mb="xs"
                    as="a"
                    href="https://www.threepeakschallenge.uk/"
                    target="_blank"
                  >
                    → Three Peaks Challenge
                  </Text>
                  <Text
                    variant="subhead"
                    sx={{
                      color: 'green.dark',
                      display: 'block',
                      textDecoration: 'none',
                    }}
                    as="a"
                    href="https://settle-carlisle.co.uk/"
                    target="_blank"
                  >
                    → Settle Carlisle Railway Line
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </BackgroundImage>

        <StayTheNight />
      </Box>
    </>
  )
}

export default Nearby
